@import './calendar';

// simple helper classes
.h-2-5em {
  height: 2.5em;
}

.mh-10 {
  min-height: 10em;
}

.mh-14 {
  min-height: 14em;
}

// do not show border for navbar-toggler
.navbar-toggler {
  border-width: 0;
}

.navbar-collapse {
  box-shadow: none;
}

// make large form control font bigger to prevent iOS Safari auto-zooming in form
.form-control-lg {
  font-size: 1rem;
}

// project card
.card-project:hover {
  background-color: #f5f5f5;
}

.project-info p {
  font-size: 0.875rem;
  margin-bottom: 0;
}

// breadcrumb theming
.breadcrumb-theme-light {
  a,
  h6,
  .breadcrumb-item,
  .breadcrumb-item + .breadcrumb-item::before {
    color: $dark;
  }
}

.breadcrumb-theme-dark {
  a,
  h6,
  .breadcrumb-item,
  .breadcrumb-item + .breadcrumb-item::before {
    color: $white;
  }
}

// small width for add new location tab +
.location-tabs-admin li:last-child {
  flex-grow: 0;
}

// use same font color in disabled buttons
@each $color, $value in $btn-font-colors {
  .btn-#{$color}:disabled,
  .btn.bg-gradient-#{$color}:disabled {
    color: $value;
  }
}
