$hairline: #DADCE0;

.location-tabs .nav-link {
  background-color: #f5f5f5;
}

.location-tabs .nav-link.active {
  background-color: $white;
  font-weight: 700;
}

.cal-nav button {
  height: 4em;
  width: 4em;
}

.cal-container {
  display: flex;
  align-items: flex-start;
  flex: 1 1 auto;
  width: 100%;
  overflow-x: auto;
}

.cal-content {
  min-width: 100%;
  flex: none;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  padding-top: 4em;
}

.cal-hour-row::after {
  content: "";
  border-bottom: $hairline 1px solid;
  position: absolute;
  width: 100%;
  margin-top: -1px;
  z-index: 3;
  pointer-events: none;
}

.cal-hour-number {
  position: relative;
  font-size: 10px;
  top: -8px;
  padding-right: 8px;
}

.cal-day-col {
  position: relative;
  border-right: $hairline 1px solid;
  flex: 1 1 auto;
  flex-shrink: 0;
  min-width: 50px;
}

.cal-day-col-title {
  position: absolute;
  top: -3.75em;
}

.cal-day-col-title-today {
  background-color: $primary;
  width: 2em;
  height: 2em;
  line-height: 2em;
  margin-top: -0.2em;
  margin-left: auto;
  margin-right: auto;
  border-radius: 100%;
  color: $white;
}

.cal-day-col-content {
  position: relative;
  width: calc(100% - 1em);
  height: 100%;
  padding-right: 3em;
  font-size: 12px;
}

.cal-slot {
  position: absolute;
  padding: .25em;
  z-index: 5;
  width: 100%;
  border-radius: 3px;
  line-height: 1em;
}
